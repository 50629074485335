@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
#posiciona {
  position:absolute ; /* posição absoluta ao elemento pai, neste caso o BODY */
  /* Posiciona no meio, tanto em relação a esquerda como ao topo */
  left: 0%; 
  top: 0%;
  width: 100%; /* Largura da DIV */
  height: 100%; /* Altura da DIV */
  /* A margem a esquerda deve ser menos a metade da largura */
  /* A margem ao topo deve ser menos a metade da altura */
  /* Fazendo isso, centralizará a DIV */
  margin-left: 0px;
  margin-top: 0px;
  text-align: center; /* Centraliza o texto */
  align-content: center;
  align-items: center; 
  vertical-align: middle;
  z-index: 1000; /* Faz com que fique sobre todos os elementos da página */
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 10px;
  font-size: calc(3px + 1vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
}
.App {
  background-image: url(/static/media/background.3f1b4972.webp);
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}

.Topo {
  background-image: url(/static/media/background.3f1b4972.webp);
  background-attachment: scroll;
  background-size: cover;
  background-color: black;
  height: 1000px;
  min-height: 600px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center; 
  color: #000;
  -webkit-text-decoration-color: #000;
          text-decoration-color: #000;
  font-size: calc(12px + 3vmin);

}
.TopoVideo {
  background-attachment: scroll;
  background-size: cover;
  background-color: black;
  height: 1000px;
  min-height: 600px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center; 
  color: #000;
  -webkit-text-decoration-color: #000;
          text-decoration-color: #000;
  font-size: calc(12px + 3vmin);

}
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}



.Menu {
  background-color: #fff;
	height:auto;
  display: flex;
  flex-direction: line;
  text-align: center;
  align-items: center;
  align-content: space-around;
  white-space: inherit;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Patrimonio {
  background-image: url(/static/media/patrimonioqueijo.af74b9e8.png);
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
  min-width: 50%;
  min-height: 300px;
	height:auto;
  display: flex;
  flex-direction: row;
}
.Inicio {
  background-color: #f5cf29;
	max-width:100%;
  min-height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 300px;
  font-size: calc(10px + 2vmin);
  padding: 50px;
}

.Trabalhos {
  background-color: white;
	width: 100%;
	height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  color: black;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.TrabalhoInterno {
  background-color: #fff;
  display: inline-block;
  align-content: space-between;
  margin: 10px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  height: auto;
  width: 48%;
  max-width: 48%;
  min-height: auto;
  font-size: calc(8px + 2vmin);
}
.Caminhos {
  background-color: white;
	width:100%;
	height:auto;
  display: flex;
  align-content: center;
  text-align: center;
  flex-direction: column;
  color: black;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.CaminhosBreve {
  background-color: white;
	width:100%;
	height:auto;
  display: flex;
  align-content: center;
  text-align: center;
  flex-direction: row;
  color: black;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.CaminhosInterno {
  background-color: #6b6964;
  height: 700px;
  width: 1200px;
  max-width: 1200px;
  min-width: 200px;
  max-height: 700px;
  min-height: 200px;
  border-radius: 1em;
	height:auto;
  display: flex;
  flex-direction: row;
  color: black;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
}
.CaminhosInternoBreve {
  background-color: #6b6964;
  height: 300px;
  width: 300px;
  max-width: 300px;
  min-width: 100px;
  max-height: 300px;
  min-height: 100px;
  border-radius: 1em;
	height:auto;
  display: flex;
  flex-direction: row;
  color: black;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
}
.Noticias {
  background-color: #3D3E94;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Cidades {
  background-color: #515557;
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Cidadescada {
  background-color: #fff;
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  font-size: calc(8px + 2vmin);
}
.Receptivos {
  background-color: #AACE45;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Parceiros {
  background-color: #058A51;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 200px;
  font-size: calc(8px + 2vmin);
}
.Rodape {
  background-color: #787878;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 150px;
  font-size: calc(5px + 2vmin);
}
.Tonatrilha {
  background-color: #424242;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(6px + 2vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-logo {
  width: 30%;
  max-width: 30%;
  min-width: 10%;
  height: auto;
  margin: 10px;
  align-content: center;
}

.Img {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 500px;
  margin: 10px;
}
.App-celular {
	max-width:100%;
  min-width: 10%;
  height:auto;
  width: 230px;
  margin: 10px;
}

.App-trilha {
	max-width:100%;
  min-width: 5%;
  height:auto;
  width: 145.55px;
}
.App-trilha2 {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 100px;
  margin: 5px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-divesquerda {
  width: 15%;
	max-width:15%;
  min-width: 8%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.App-divdireita {
  width: 80%;
  max-width:80%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.Textocircuito-divesquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  margin: 10px;
  font-size: calc(10px + 2vmin);
  padding: 20px;
}
.App-divdireitat {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 3vmin);
  color: black;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divesquerdab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.App-divdireitab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.Caminhosimg {
  height: 360px;
  width: 520px;
  max-width: 5200px;
  min-width: 100px;
  max-height: 360px;
  min-height: 100px;
  border-radius: 1em;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.CaminhosimgBreve {
  height: 200px;
  width: 260px;
  max-width: 260px;
  min-width: 100px;
  max-height: 200px;
  min-height: 80px;
  border-radius: 0.5em;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.App-content {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  -webkit-transform: 'translate(-50%, -50%)';
          transform: 'translate(-50%, -50%)';
}

a:link, a:visited, a:active {
	text-decoration:none;
  color: #000;
	}
a:hover {
	text-decoration:none; 
	color:#696867; 
	}

.action{
    padding: 0px;
    margin: 10px;
    align-items: center;
    display: inline-block;
    border-radius: 4px;;
  }


.sidebar{
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #181C2e;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar div{
  background: url(/static/media/cover.6a293bc8.png);
  background-color: #181C2e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}

.sidebar div{
  padding-top: 30px;
}

.sidebar div img {
  border-radius: 50%;
  display: block;
  margin:auto;
  width: 90px;
  height: 90px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212);
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover;
}

.sidebar a{
  display: block;
  color: rgba(255,255,255, 0.7);
  padding: 16px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: ease-in-out .4s;
}

.sidebar a svg{
  margin-right: .5em;
}

.sidebar a:hover{
  background-color: #121212;
  color: #FFF;
}

.content{
  margin-left: 200px;
  padding: 1px 16px;
}

@media screen and (max-width: 700px){
  .sidebar{
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content{
    margin-left: 0;
  }

  .sidebar a{
    float: left;
  }
  .sidebar div{
    display: none;
  }

  .sidebar a svg{
    display: none;
  }
}


@media screen and (max-width: 400px){
  .sidebar a {
    text-align: center;
    float: none;
  }

  .sidebar a svg{
    display: none;
  }
}

.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: .8em;
}

.title span{
  margin-left: 1em;
  font-size: 1.5em;
}

.modal{
  position: fixed;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  background: rgba(0,0,0, 0.6);
  z-index: 99;
}

.modal.container{
  position: fixed;
  max-width: 600px;
  top: 15%;
  left:0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #FFF;
  box-shadow: 0 0 20px rgba(0,0,0, 0.8);
}

.close{
  background-color: #F65835;
  border:0;
  color: #FFF;
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal span{
  font-weight: bold;
  font-size: 1.2em;
  color: #121212;
}

.modal span i{
  font-weight: 400;
  margin-right: 1em;
  padding: 2px 8px;
  border-radius: 3px;
}

.row{
  margin-bottom: 1em;
}

.modal p{
  padding-top: 0.5em;
  white-space: pre-wrap;
}

.container-center{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
}

.login{
  background: #EAEAEC;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-area{
  display: flex;
  justify-content: center;
  background-color: #181c2e;
  width: 100%;
}

.login-area img{
  padding: 20px;
  width: 200px;
  height: auto;
}

form{
  margin-top: 1.5em;
  width: 90%;
  display: flex;
  flex-direction: column;
}

form h1{
  text-align: center;
  margin-bottom: 0.5em;
  color: #181c2e;
}

.login input{
  margin-bottom: 15px;
  height: 35px;
  border:0;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
  background-color: #FFF;
}

form button{
  height: 35px;
  border:0;
  border-radius: 7px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.3em;
}

.login a{
  margin: 1.5em 0;
  color: #000;
  cursor: pointer;
}




.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

.modal{
  position: fixed;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  background: rgba(0,0,0, 0.6);
  z-index: 99;
}

.modal .container{
  position: fixed;
  max-width: 600px;
  top: 15%;
  left:0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #FFF;
  box-shadow: 0 0 20px rgba(0,0,0, 0.8);
}

.close{
  background-color: #F65835;
  border:0;
  color: #FFF;
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal span{
  font-weight: bold;
  font-size: 1.2em;
  color: #121212;
}

.modal span i{
  font-weight: 400;
  margin-right: 1em;
  padding: 2px 8px;
  border-radius: 3px;
}

.row{
  margin-bottom: 1em;
}

.modal p{
  padding-top: 0.5em;
  white-space: pre-wrap;
}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

.container{
  display: flex;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: .8em;
  align-items: center;
  margin-bottom: 1em;
}

.label-avatar{
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

}

.label-avatar input{
  display: none;
}

.label-avatar span{
  z-index: 99;
  position: absolute;
  opacity: 0.7;
  transition: all .5s;
}

.label-avatar span:hover{
  opacity: 1;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.form-profile img{
  margin-bottom: 1em;
  border-radius: 50%;
  object-fit: cover;
}

.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-profile input, textarea, select{
  margin-bottom: 1em;
  padding: .7em;
  border:0;
  border-radius: 5px;
  max-width: 600px;
}

.form-profile input:disabled{
  cursor: not-allowed;
}

.form-profile button{
  max-width: 600px;
}

.logout-btn{
  padding: 8px 20px;
  background-color: transparent;
  border:1px solid #121212;
  border-radius: 5px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}




form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.label-avatar{
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

}

.label-avatar input{
  display: none;
}

.label-avatar span{
  z-index: 99;
  position: absolute;
  opacity: 0.7;
  transition: all .5s;
}

.label-avatar span:hover{
  opacity: 1;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.form-profile img{
  margin-bottom: 1em;
  border-radius: 50%;
  object-fit: cover;
}

.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-profile input, textarea, select{
  margin-bottom: 1em;
  padding: .7em;
  border:0;
  border-radius: 5px;
  max-width: 600px;
}

.form-profile input:disabled{
  cursor: not-allowed;
}

.form-profile button{
  max-width: 600px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.label-avatar{
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

}

.label-avatar input{
  display: none;
}

.label-avatar span{
  z-index: 99;
  position: absolute;
  opacity: 0.7;
  transition: all .5s;
}

.label-avatar span:hover{
  opacity: 1;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.form-profile img{
  margin-bottom: 1em;
  border-radius: 50%;
  object-fit: cover;
}

.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-profile input, textarea, select{
  margin-bottom: 1em;
  padding: .7em;
  border:0;
  border-radius: 5px;
  max-width: 600px;
}

.form-profile input:disabled{
  cursor: not-allowed;
}

.form-profile button{
  max-width: 600px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}


form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus{
  outline: 0;
}

html, body, #root{
  height: 100%;
}

body{
  font: 14px 'Roboto', sans-serif;
  background-color: #EFEFEF;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button{
 cursor: pointer; 
}
