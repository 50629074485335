#posiciona {
  position:absolute ; /* posição absoluta ao elemento pai, neste caso o BODY */
  /* Posiciona no meio, tanto em relação a esquerda como ao topo */
  left: 0%; 
  top: 0%;
  width: 100%; /* Largura da DIV */
  height: 100%; /* Altura da DIV */
  /* A margem a esquerda deve ser menos a metade da largura */
  /* A margem ao topo deve ser menos a metade da altura */
  /* Fazendo isso, centralizará a DIV */
  margin-left: 0px;
  margin-top: 0px;
  text-align: center; /* Centraliza o texto */
  align-content: center;
  align-items: center; 
  vertical-align: middle;
  z-index: 1000; /* Faz com que fique sobre todos os elementos da página */
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 10px;
  font-size: calc(3px + 1vmin);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
}
.App {
  background-image: url("../imagens/background.webp");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
}

.Topo {
  background-image: url("../imagens/background.webp");
  background-attachment: scroll;
  background-size: cover;
  background-color: black;
  height: 1000px;
  min-height: 600px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center; 
  color: #000;
  text-decoration-color: #000;
  font-size: calc(12px + 3vmin);

}
.TopoVideo {
  background-attachment: scroll;
  background-size: cover;
  background-color: black;
  height: 1000px;
  min-height: 600px;
  height:auto;
  width:1440px;
  max-width:100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center; 
  color: #000;
  text-decoration-color: #000;
  font-size: calc(12px + 3vmin);

}
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}



.Menu {
  background-color: #fff;
	height:auto;
  display: flex;
  flex-direction: line;
  text-align: center;
  align-items: center;
  align-content: space-around;
  white-space: inherit;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Patrimonio {
  background-image: url("../imagens/patrimonioqueijo.png");
  background-attachment: scroll;
  background-size: auto;
  background-color: black;
	max-width:100%;
  min-width: 50%;
  min-height: 300px;
	height:auto;
  display: flex;
  flex-direction: row;
}
.Inicio {
  background-color: #f5cf29;
	max-width:100%;
  min-height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 300px;
  font-size: calc(10px + 2vmin);
  padding: 50px;
}

.Trabalhos {
  background-color: white;
	width: 100%;
	height: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.TrabalhoInterno {
  background-color: #fff;
  display: inline-block;
  align-content: space-between;
  margin: 10px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  height: auto;
  width: 48%;
  max-width: 48%;
  min-height: auto;
  font-size: calc(8px + 2vmin);
}
.Caminhos {
  background-color: white;
	width:100%;
	height:auto;
  display: flex;
  align-content: center;
  text-align: center;
  flex-direction: column;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.CaminhosBreve {
  background-color: white;
	width:100%;
	height:auto;
  display: flex;
  align-content: center;
  text-align: center;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
  font-size: calc(8px + 2vmin);
}
.CaminhosInterno {
  background-color: #6b6964;
  height: 700px;
  width: 1200px;
  max-width: 1200px;
  min-width: 200px;
  max-height: 700px;
  min-height: 200px;
  border-radius: 1em;
	height:auto;
  display: flex;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
}
.CaminhosInternoBreve {
  background-color: #6b6964;
  height: 300px;
  width: 300px;
  max-width: 300px;
  min-width: 100px;
  max-height: 300px;
  min-height: 100px;
  border-radius: 1em;
	height:auto;
  display: flex;
  flex-direction: row;
  color: black;
  text-decoration-color: black;
  margin: 1em;
  padding: 1em;
  font-size: calc(5px + 2vmin);
}
.Noticias {
  background-color: #3D3E94;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Cidades {
  background-color: #515557;
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Cidadescada {
  background-color: #fff;
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-width: 200px;
  min-width: 100px;
  min-height: 100px;
  font-size: calc(8px + 2vmin);
}
.Receptivos {
  background-color: #AACE45;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 300px;
  font-size: calc(8px + 2vmin);
}
.Parceiros {
  background-color: #058A51;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 200px;
  font-size: calc(8px + 2vmin);
}
.Rodape {
  background-color: #787878;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 150px;
  font-size: calc(5px + 2vmin);
}
.Tonatrilha {
  background-color: #424242;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(6px + 2vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-logo {
  width: 30%;
  max-width: 30%;
  min-width: 10%;
  height: auto;
  margin: 10px;
  align-content: center;
}

.Img {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 500px;
  margin: 10px;
}
.App-celular {
	max-width:100%;
  min-width: 10%;
  height:auto;
  width: 230px;
  margin: 10px;
}

.App-trilha {
	max-width:100%;
  min-width: 5%;
  height:auto;
  width: 145.55px;
}
.App-trilha2 {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 100px;
  margin: 5px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-divesquerda {
  width: 15%;
	max-width:15%;
  min-width: 8%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.App-divdireita {
  width: 80%;
  max-width:80%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.Textocircuito-divesquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
  margin: 10px;
  font-size: calc(10px + 2vmin);
  padding: 20px;
}
.App-divdireitat {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 3vmin);
  color: black;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  margin: 30px;
}
.App-divesquerdab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.App-divdireitab {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  color: black;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.Caminhosimg {
  height: 360px;
  width: 520px;
  max-width: 5200px;
  min-width: 100px;
  max-height: 360px;
  min-height: 100px;
  border-radius: 1em;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.CaminhosimgBreve {
  height: 200px;
  width: 260px;
  max-width: 260px;
  min-width: 100px;
  max-height: 200px;
  min-height: 80px;
  border-radius: 0.5em;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.App-content {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

a:link, a:visited, a:active {
	text-decoration:none;
  color: #000;
	}
a:hover {
	text-decoration:none; 
	color:#696867; 
	}

.action{
    padding: 0px;
    margin: 10px;
    align-items: center;
    display: inline-block;
    border-radius: 4px;;
  }
